/*! Defaults design for the lists and special formatting */

ul,
ol {
	display: flex;
	list-style-type: disc;
	flex-direction: column;
	padding-left: 2rem;

	&.no-style-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	&.bullet-list {
		list-style-type: disc;
		list-style-position: outside;
	}
}

.markdown-content {
	ul li,
	ol li {
		margin-bottom: 0;

		.code-toolbar {
			margin-bottom: 1rem;
		}
	}

	/* If the <li> is the last child of a <ul> or <ol> */
	ul :last-child:is(li),
	ol :last-child:is(li) {
		/* Last child of the <li> */
		& :last-child:is(.code-toolbar) {
			margin-bottom: 0;
		}
	}

	li + li {
		margin-top: 0.25em;
	}
}
